#hometrunkey {
  background: url('../Images/trunkeybg.png') no-repeat center center;
  display: table;
  height: 400px;
  position: relative;
  width: 100%;
  background-size: cover;
}
.trunkey_imgtext {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.trunkey_imgtext p {
  font-size: 150%;
  color: #4b5563;
}
.trunkey_imgtext h3 {
  font-size: 300%;
  font-weight: 400;
}
.trunkey_para {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: Sofia Sans;
  font-size: 113%;
  padding: 3% 10% 3% 10%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.trunkey_offer {
  background-color: #f5f7ff;
}
.trunkey_offer img {
  width: 100%;
}
.trunkey_offer p {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: Sofia Sans;
  font-size: 300%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  

}

.trunkey_design {
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: space-around;
  padding: 2% 20% 2% 20%;
  gap: 10%;
}
.trunkey_design_process img {
  width: auto;
  height: auto;
}
.trunkey_line {
  margin: 5px 0;
  height: 5px;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 10px,
    #FFE5CA 10px,
    #FFE5CA 20px
  );
  /*10px transparent then 10px black -> repeat this!*/
}
.commerial_design {
  display: flex;
  text-align: left;
  justify-content: space-around;
  padding: 2% 20% 2% 20%;
  gap: 10%;
}
.design_process img {
  width: 8vh;
  height: auto;
}
.design_process p {
  color: #5e6a7b;
  font-family: Sofia Sans;
  font-size: 88%;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.design_process h4 {
  color: var(--gray-900, #18181b);
  font-family: Sofia Sans;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  margin-left: 4%;

  letter-spacing: 0.2px;
}

.thumbnail {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.caption h4 {
  color: white;
  margin: 0;
  font-size: 250%;
  height: 10rem;
  margin-top: -30%;
}

.trunkey_design_process p {
  color: #5e6a7b;
  font-family: Sofia Sans;
  font-size: 88%;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.trunkey_design_process h4 {
  color: var(--gray-900, #18181b);
  font-family: Sofia Sans;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  margin-left: 4%;
  letter-spacing: 0.2px;
}
.trunkey_thumbnail {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}


.trunkey_caption h1 {
  color: white;
  margin: 0;
  font-size: 190%;
  height: 8rem;
  background: transparent;
  margin-top: -30%;
}
.trunkey_image-container img {

  cursor: pointer;
}

.trunkey_popup {
  position: fixed;
  top: 2%;
  left: 10%;
  width: 80%;
  height: auto;
  background-color:rgb(227, 227, 227);
  display: flex;
  padding: 2%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}
.trunkey_popup img{
  width: 400px;
height: 300px;
flex-shrink: 0;
}
.trunkey_blur {
  filter: blur(2px);
  /*Adjusttheblurstrengthasneeded*/pointer-events: none;
  /*Preventinteractionwiththeblurredcontent*/
  -webkit-filter: blur(2px);
}
.trunkey_popup-content {
  border-radius: 5px;
  padding: 20px;
  position: relative;
  max-width: 70%;
  text-align: center;
}

.trunkey_close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.trunkey_blur-background {
  filter: blur(5px); /* Adjust the blur strength as needed */
  pointer-events: none; /* Prevent interaction with the blurred content */
}

.trunkey_image-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.trunkey_image-list img {
  width: 100px;
  height: 100px;
  margin: 10px;
  cursor: pointer;
}

.trunkey_image-list img:hover {
  border: 2px solid #333;
}

.trunkey_offer .gola.gola1, .trunkey_offer .gola.gola2, .trunkey_offer .gola.gola3, .trunkey_offer .gola.gola4 {
  margin-left: 20px !important;
  margin-top: 7px !important;
}

@media screen and ( min-width : 1200px ) {
  .res_design .deskSpecial{
    padding-top: 0px !important;
  }
}

@media ( max-width : 720px ) and ( min-width : 700px ) {
  #hometrunkey {
    background: url('../Images/trunkeybg.png') no-repeat center center;
    display: table;
    height: 300px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .trunkey_imgtext h3{
    font-size: 33px !important;
    font-weight: 400;

  }
  .trunkey_imgtext p{
line-height: 0;
  }
  
  .trunkey_para{
    text-align: justify;
    padding: 30px 40px;
  }

  .trunkey_offer p{
    font-size: 16px;
    padding: 15px 0;
  }
  .trunkey_offer br{
    display: block;
  }
  .design_process img{
    height: 10vh;
  }
  .design_process.trunSpc img{
    height: 12vh;
    margin-bottom: -0% !important;
  }
 
  .trunkey_offer .headP{
    font-size: 28px;
  }
  .mob-height{
    height: 190px;
  }
  .design_process .desProcess{
    padding-top: 0;
  }
  .design_process .desh4{
    margin-left: 0.5rem !important;
  }
  .design_process.trunSpc p {
    font-size: 14px;
}
}

@media screen and (max-width: 600px) {
  .trunkey_design {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  br{
    display: none;
  }
}

@media screen and ( max-width : 480px ) {
  #hometrunkey {
    background: url('../Images/trunkeybg.png') no-repeat center center;
    display: table;
    height: 220px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .trunkey_imgtext h3{
    font-size: 33px !important;
    font-weight: 400;

  }
  .trunkey_imgtext p{
line-height: 0;
  }
  
  .trunkey_para{
    text-align: justify;
    padding: 30px 40px;
  }
  .trunkey_offer p{
    font-size: 16px;
    padding: 15px 0;
  }
  .trunkey_offer br{
    display: block;
  }
  .design_process img{
    height: 7vh;
  }
  .design_process.trunSpc img{
    height: 8vh;
  }
  .trunkey_offer .headP{
    font-size: 24px;
  }
  .mob-height{
    height: 140px;
  }
}

