
#inthome {
    /* background: url('../Images/Ineriorresiden.png') no-repeat center center; */
    display: table;
    height: 400px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .res_imgtext {
    background: url('../Images/Ineriorresiden.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .res_imgtext p {
    font-size: 150%;
    color: #4b5563;
  }
  .res_imgtext h3 {
    font-size: 300%;
    font-weight: 400;
  }
  .headings{
    border-radius: 8px;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: -2px -4px 12px 0px rgba(0, 0, 0, 0.10) inset;
  backdrop-filter: blur(16px);
  padding: 6px 0px;
  }
  .res_para {
    color: var(--gray-900, #18181b);
    text-align: center;
    font-family: Sofia Sans;
    font-size: 113%;
    padding: 3% 10% 3% 10%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .res_offer {
    background-color: #f5f7ff;
  }
  .res_offer img {
    width: 100%;
  }
  .res_offer p {
    color: var(--gray-900, #18181b);
    text-align: center;
    font-family: Sofia Sans;
    font-size: 300%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .image-display{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* gap: 5%; */
  }
  .res_design {
    display: flex;
    text-align: left;
    justify-content: space-around;
    padding: 2% 10%;
    gap: 10%;
  }
  .design_process img {
    width: auto;
    height: auto;
  }
  .design_process p {
    color: #5e6a7b;
    font-family: Sofia Sans;
    font-size: 88%;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .design_process h4 {
    color: var(--gray-900, #18181b);
    font-family: Sofia Sans;
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    margin-left: 4%;
    letter-spacing: 0.2px;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    height: 10rem;
    margin-top: -30%;
    cursor: pointer;
  }
  /* ImagePopup.css */
  .gola{
    position: absolute;
    width: 2.5% !important;
    top: 34.5%;
    z-index: 10;
  }
  
  .gola.gola1, .gola.gola2, .gola.gola3, .gola.gola4 {
    margin-left: 20px !important;
  }

.image-container img {
 
  cursor: pointer;
}

.gallery-image {
  max-width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery-image.zoomed {
  transform: scale(1.5); /* You can adjust the zoom level here */
}
.popup {
  position: fixed;
  top: 2%;
  left: 0%;
  width: 100%;
  height: auto;
  background-color:white;
  display: flex;
  padding: 2%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}
.popup img{
  width: 500px;
  height: 300px;
  flex-shrink: 0;
  border-radius:5px ;
  -webkit-border-radius:5px ;
  -moz-border-radius:5px ;
  -ms-border-radius:5px ;
  -o-border-radius:5px ;
}
.blur {
  filter: blur(2px);
  /*Adjusttheblurstrengthasneeded*/pointer-events: none;
  /*Preventinteractionwiththeblurredcontent*/
  -webkit-filter: blur(2px);
}
.popup-content {
  border-radius: 5px;
  padding: 20px;
  position: relative;
  max-width: 70%;
  text-align: center;
  /* background-color: #18181b; */
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.blur-background {
  filter: blur(5px); /* Adjust the blur strength as needed */
  pointer-events: none; /* Prevent interaction with the blurred content */
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.image-list img {
  width: 100px;
  height: 100px;
  margin: 10px;
  cursor: pointer;
}

.image-list img:hover {
  border: 2px solid #333;
}
.image-list img:hover {
  border: 2px solid #333;
}
.arch-des-proc{
  top: 40%;
  left: 11%;
  width: 68% !important;
}
  .arch-des-proc hr {
    height: 0;
    margin-left: 22px;
    border-top: 4px dashed #ff8200;
    background-color: transparent;
}
@media screen and ( min-width : 1200px ) {
  .res_design .deskSpecial{
    padding-top: 0px !important;
  }
}

@media ( max-width : 720px ) and ( min-width : 700px ) {

  #inthome {
    /* background: url('../Images/Ineriorresiden.png') no-repeat center center; */
    display: table;
    height: 300px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .res_imgtext {
    background: url('../Images/Ineriorresiden.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .res_imgtext h3{
    font-size: 33px !important;
  }
  .res_imgtext p{
    line-height: 0;
  }
    .gola, .gola.gola4, .gola.gola1, .gola.gola2, .gola.gola3 {
      display: none !important;
    }
     .res_offer img {
      height: 190px;
    }
    .res_offer p {
      font-size: 28px;
    }
    .res_offer br{
      display: block;
    }
    .res_para{
      text-align: justify;
      padding: 30px 40px;
    }
    .res_design {
      display: flex;
      text-align: left;
      justify-content: center;
      padding: 2% 10%;
      gap: 10%;
      flex-direction: column;
      align-items: center;
  }
  .design_process {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
    .design_display {
      display: flex;
      flex-direction: column;
    }
    .design_display p{
      margin-top: 0% !important;
      margin-bottom: 0 !important;
    }
    .design_process img{
      margin-top: 2%;
      margin-bottom: 7% !important;
    }
    .residential_design{
      padding: 3% 15%;

    }
    
  }

@media screen and (max-width: 567px) {
  .res_design {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    padding: 2% 15% 3% 7%;
    gap: 10%;
}
  .image-container img {

    cursor: pointer;
  }
  
  .popup {
    position: fixed;
    top: 2%;
    left: 5%;
    width: 90%;
    height: auto;
    background-color:rgb(227, 227, 227);
    display: flex;
    padding: 2%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
  }
  .popup img{
    width: 400px;
  height: 300px;
  flex-shrink: 0;
  }
  .blur {
    filter: blur(2px);
    /*Adjusttheblurstrengthasneeded*/pointer-events: none;
    /*Preventinteractionwiththeblurredcontent*/
    -webkit-filter: blur(2px);
  }
  .popup-content {
    border-radius: 5px;
    padding: 20px;
    position: relative;
    max-width: 70%;
    text-align: center;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  .blur-background {
    filter: blur(5px); /* Adjust the blur strength as needed */
    pointer-events: none; /* Prevent interaction with the blurred content */
  }
  
  .image-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    overflow-x:hidden;
    margin-top: 20px;
  }
  
  .image-list img {
    width: 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
  }
  
  .image-list img:hover {
    border: 2px solid #333;
  }
  .design_process{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
 
  
  .design_display{
    display: flex;
    flex-direction: column;
  }
 
  .gola.d-block {
    display: none;
  }
  .arch-des-proc{
    display: none;

  }
  
}

@media screen and ( max-width : 480px ) {

#inthome {
  /* background: url('../Images/Ineriorresiden.png') no-repeat center center; */
  display: table;
  height: 220px;
  position: relative;
  width: 100%;
  background-size: cover;
}
.res_imgtext {
  background: url('../Images/Ineriorresiden.png') no-repeat center center;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background-size: cover;
}
.res_imgtext h3{
  font-size: 33px !important;
}
.res_imgtext p{
  line-height: 0;
}
  .gola, .gola.gola4, .gola.gola1, .gola.gola2, .gola.gola3 {
    display: none !important;
  }
   .res_offer img {
    height: 140px;
  }
  .res_offer p {
    font-size: 24px;
  }
  .res_offer br{
    display: block;
  }
  .res_para{
    text-align: justify;
    padding: 30px 40px;
  }
}
  
@media screen and (max-width:280px){
  .res_imgtext h3 {
    font-size: 28px !important;
  }
  .res_offer p {
    font-size: 24px;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
}
.design_display {
  display: flex;
  flex-direction: column;
}
.design_display p{
  margin-top: -5% !important;
}
.design_process img{
  margin-bottom: 19% !important;
}
.residential_design{
  padding: 0% 8% !important;
}
}