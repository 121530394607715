#intcomhome {
  /* background: url('../Images/Ineriorresiden.png') no-repeat center center; */
  display: table;
  height: 400px;
  position: relative;
  width: 100%;
  background-size: cover;
}
.intcom_imgtext {
  background: url('../Images/InteriorCommercial.png') no-repeat center center;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background-size: cover;
}
  .intcom_imgtext p {
    font-size: 150%;
    color: #4b5563;
  }
  .intcom_imgtext h3 {
    font-size: 300%;
    font-weight: 400;
  }
  .headings{
    border-radius: 8px;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: -2px -4px 12px 0px rgba(0, 0, 0, 0.10) inset;
  backdrop-filter: blur(16px);
  padding: 6px 0px;
  }
  .intcom_para {
    color: var(--gray-900, #18181b);
    text-align: center;
    font-family: Sofia Sans;
    font-size: 113%;
    padding: 3% 10% 3% 10%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .intcom_offer {
    background-color: #f5f7ff;
  }
  .intcom_offer img {
    width: 100%;
  }
  .intcom_offer p {
    color: var(--gray-900, #18181b);
    text-align: center;
    font-family: Sofia Sans;
    font-size: 300%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    padding: 1%;
  }
  
  .intcom_design {
    display: flex;
    text-align: left;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  .design_process img {
    width: auto;
    height: auto;
  }
  .design_process p {
    color: #5e6a7b;
    font-family: Sofia Sans;
    font-size: 88%;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .design_process h4 {
    color: var(--gray-900, #18181b);
    font-family: Sofia Sans;
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    margin-left: 4%;
  
    letter-spacing: 0.2px;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    height: 10rem;
    margin-top: -30%;
  }
  .res_offer .gola, .intcom_offer .gola {
    top: 29.5%;
    /* margin-top: -30px !important; */
  }
  .arch-des-proc.hr-intRes {
    top: 33.7%;
    left: 11%;
    width: 68% !important;
  }
  @media ( max-width : 720px ) and ( min-width : 700px ){
    #intcomhome {
      /* background: url('../Images/Ineriorresiden.png') no-repeat center center; */
      display: table;
      height: 300px;
      position: relative;
      width: 100%;
      background-size: cover;
    }
    .intcom_imgtext {
      background: url('../Images/InteriorCommercial.png') no-repeat center center;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      background-size: cover;
    }
      .intcom_imgtext p {
    line-height: 0;
      }
      
      .intcom_imgtext h3 {
        font-size: 33px !important;
        
      }
    .intcom_para{
      padding: 30px 40px;
      text-align: justify;
    }
    .intcom_offer p{
      font-size: 28px ;
    }
    .intcom_offer br{
      display: block;
    }
    .intcom_offer img {
      height: 190px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .intcom_design {
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: space-around;
      padding: 2% 20% 2% 20%;
      gap: 10%;
    }
    .design_process{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
   
    
    .design_display{
      display: flex;
      flex-direction: column;
    }
   
    .gola.d-block {
      display: none;
    }
    .arch-des-proc{
      display: none;
  
    }
  }

  @media screen and ( max-width : 480px ) {
    #intcomhome {
      /* background: url('../Images/Ineriorresiden.png') no-repeat center center; */
      display: table;
      height: 220px;
      position: relative;
      width: 100%;
      background-size: cover;
    }
    .intcom_imgtext {
      background: url('../Images/InteriorCommercial.png') no-repeat center center;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      background-size: cover;
    }
      .intcom_imgtext p {
    line-height: 0;
      }
      
      .intcom_imgtext h3 {
        font-size: 33px !important;
        
      }
    .intcom_para{
      padding: 30px 40px;
      text-align: justify;
    }
    .intcom_offer p{
      font-size: 24px ;
    }
    .intcom_offer br{
      display: block;
    }
    .intcom_offer img {
      height: 140px;
    }
  }
  

  @media screen and (max-width:280px){
    .intcom_imgtext h3 {
      font-size: 28px !important;
    }
    .intcom_offer p {
      font-size: 24px;
      padding-left: 8%;
      padding-right: 8%;
  }
  }