.project-text{
    top: 40%;
    left: 0%;
    text-align: center;
    color: white;
}

@media ( max-width : 720px ) and ( min-width : 700px ){
    .offer{
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .col-md-6.offer{
        width: 100%;
    }
    .col-md-6.offer h1{
        font-size: medium;
    }
    .project-text h1{
        font-size: 26px !important;
    }
}

@media screen and ( max-width : 280px ) {
    .project-text h1  {
      font-size: 18px !important;
  }
  .project-text h2  {
    font-size: 18px !important;
}
.col-md-6.offer{
    padding: 10px 0px !important;
}
}