.contacthome{
    display: table;
    height: 400px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-color: #F5F7FF;
}
.contact_main{
  padding-bottom: 5%;
}
.contact_form input {
    width: 100%;
    padding: 2px 20px;
    margin: 1px 0;
    box-sizing: border-box;
    border:  solid grey;
    border-width:0px 0px 0.5px 0px;
 }
 .contact_form {
    text-align: left;
 }
 .contact_form input:focus {
    outline:none;
    /* border-width:0px 0px 1px 0px; */
 }
 .contact_form select {
    width: 100%;
    padding: 10px 20px;
    margin: 1px 0;
    color: rgba(55, 65, 79, 0.8) !important;
    font-size: 16px;
    box-sizing: border-box;
    border:  solid grey;
    border-width:0px 0px 0.5px 0px;
 }
 .contact_form select:focus {
    outline:none;
    /* border-width:0px 0px 0.5px 0px; */
 }
 .contact_form button{
    border-radius: 4px;
    background: #4B5563;
   color: white;
   width: 100%;
   -webkit-border-radius: 4px;
   -moz-border-radius: 4px;
   -ms-border-radius: 4px;
   -o-border-radius: 4px;
   border: none;
}

 .contactbox{
    background-color: rgba(153, 153, 153, 0.20);
 }
 .location{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    border-right: 1px dashed gray;
 }
 .contact_head{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3%;
    gap: 3%;
 }
 .contact_border{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:5%;
    
     }
     .contact_display{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    bottom: -78%;
    left: 16%; */
    background-color: white; /* Optional: Add a semi-transparent background */
     }
     
     .image-container {
      position: relative;
      overflow: hidden;
      left: 20%;
      top: 9%;
    }
    
    .text-overlay {
      position: absolute;
      top: 3%;
      left: 30%;
      text-align: center;
      padding: 27px 10px 20px 0px;
      margin-top: 10px;
    }
    .left-aligned{
      text-align: left;
      display: flex;
    }

     /* Contact-us */
.contact-us-background {
   /* background-image: url(../Images/Contact.webp);
   background-repeat: no-repeat;
   background-size: cover; */
   background: linear-gradient(270deg, #F1F9FF 0%, rgba(241, 249, 255, 0) 100%) !important;
 }
 .contact-title {
   color: #2c2c2c;
   font-size: 48px;
   font-weight: 700;
   text-transform: capitalize;
 }
 .contact-text {
   color: #1e1e1e;
   font-size: 20px;
   font-weight: 400;
 }
 .contact-label {
  color: #1F2937;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 50px;
letter-spacing: 1.2px;
 }
 .contact-details {
   color: black;
   margin-top: 5px;
   font-size: 24px;
   font-weight: 400;
 }
 a.contact-details:hover {
   color: #1e395f;
 }
 .contact-left {
   padding: 40px 75px 100px 40px;
 }
 .contact-right {
   padding: 5px 75px 100px 40px;
 }
 .social-links {
   padding: 5px 33px 0px 3px;
 }
 
 /* Form */
 .contact-us form {
   width: 100%;
   padding: 10px 50px 30px 50px;
   height: 100%;
   background-color: white;
   /* background-color: #F9F9F9; */
   border-radius: 5px;
   box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.1);;
 }
 .contact-form-label {
   display: block;
   color: rgba(55, 65, 79, 0.8);
   text-align: left;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
 }
 .contact-forn-btn{
  width: 100%;
  border-radius: 4px;
  color: white;
background: #4B5563;
border: none;
padding: 1%;
 }
 
 .contact-form-input {
     /* background-color:  #F9F9F9; */
   box-sizing: border-box;
   width: 100%;
   outline: none;
   border: none;
   border-bottom: 1px solid#D9D9D9;
   font-size: 16px;
 }
 select.selectPlaceholder{
  color: rgba(55, 65, 79, 0.8) !important;
 }
 select.contact-form-input{
  padding-bottom: 0.8rem !important;
 }
 .contact-form-textarea {
   box-sizing: border-box;
   border-color: #d9d9d9;
   border-radius: 1px;
   width: 100%;
   outline: none;
   resize: none;
 }
 .form-head {
   color: #1e1e1e;
   font-size: 20px;
   font-weight: 400;
 }
 .contact-us input[type="text"]:focus,
 .contact-us input[type="tel"]:focus,
 .contact-us input[type="email"]:focus {
   border-radius: 1px;
   border-bottom: 1px solid black;
 }
 .contact-us input[type="submit"] {
   width: 100%;
   padding: 10px 10px 10px 10px;
   font-weight: bolder;
   margin-top: 30px;
   justify-content: center;
   align-items: center;
   border-radius: 50px;
   background: #1e395f;
   color:  #F9F9F9;
 }
 .contact-us textarea:focus {
   border: 1px solid black;
 }
 .contact-us .btn.btn-primary {
  border-radius: 50px;
  background: #1e395f !important;
  width: 100%;
  padding: 12px;
}
.contact-us .btn.btn-primary:hover {
  background: #2b4b77 !important;
}

.error {
  color: red;
  font-size: 14px;
}

.contact-form-textarea::placeholder{


    color: #ADADAD;


font-family: Archivo;
font-size: 16px;
font-style: normal;
font-weight: 400;

}

.contactbox a:hover{
 color: var(--primary-color) !important;
}
 
@media (min-width: 700px) and (max-width: 720px) {
.contactbox{
  margin: 2%;
}
.form-contact{
  margin: 3%;
}
.contacthome .contactbg{
height: 300px;
}

}

 @media screen and (max-width: 280px){
     .contact-left {
         padding: 0px 0px 30px 0px;
       }
       .contact-right {
         padding: 0px 0px 0px 0px;
       }
       .contact-us form {
         padding: 10px 30px 30px 30px;
       }
       .contact-details {
         font-size: 18px;
       }
       .contact-title {
         font-size: 42px;
       }
       .image-container {
        position: relative;
        left: 3%;
        top: 9%;
      }
      
      .text-overlay {
        position: absolute;
        top: 1%;
        left: 1%;
        text-align: center;
        padding: 20px;
      }
      .text-overlay h2{
        font-size: large;
        font-weight: 500;
      }

       /* .contact-us-background {
         background-image: url(../Images/Contact.webp);
         background-repeat: no-repeat;
         background-size: auto;
       } */
 
 }
 @media (min-width: 281px) and (max-width: 480px) {
   .contact-left {
     padding: 0px 10px 30px 30px;
   }
   .contact-right {
     padding: 0px 0px 0px 0px;
   }
   .contact-us form {
     padding: 10px 30px 30px 30px;
   }
   .contact-details {
     font-size: 18px;
   }
   .contact-title {
     font-size: 42px;
   }
   .image-container {
    position: relative;
    left: 1%;
    top: 9%;
  }
  
  .text-overlay {
    position: absolute;
    left: 1%;
    text-align: center;
    padding: 20px;
  }
  .text-overlay h2{
    font-size: large;
    font-weight: 500;
  }
   /* .contact-us-background {
     background-image: url(../Images/Contact.webp);
     background-repeat: no-repeat;
     background-size: cover;
   } */
 }
 
@media screen and (max-width: 767px) {
    .location{
        border: none;
    }
    .contact_border{
        display: flex;
        flex-direction: column;
        gap:5%;
        /* border-right: 1px solid #DCDCDC;
        border-bottom: 1px solid #DCDCDC; */
         }
         .image-container {
          position: relative;
          left: 3%;
          top: 9%;
        }
        
        .text-overlay {
          position: absolute;
          top: -7%;
          left: 16%;
          text-align: center;
          padding: 20px;
        }
        .text-overlay h2{
          /* font-size: large; */
          font-weight: 500;
        }
}

@media screen and ( max-width : 480px ) {
  .text-overlay{
    left: 10%;
    right: 10%;
    top: 0%;
  }
  .contacthome .image-container img {
    height: 100px;
  }
  .contactbg{
    height: 200px !important;
  }
}


@media screen and ( min-width : 320px ) and ( max-width : 400px ) {
  .contacthome .eMail{
    font-size: 13px !important;
  }
}
@media screen and  (max-width: 280px) {
  .contact_border {
    display: flex;
    flex-direction: column;
    gap: 5%;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.contact-form-label {
  margin-left: 2%;
}
  
}
