.about_main {
  background: #f5f7ff;
}
.director {
  background-color: #F2F2F2;
  padding: 2%;
}
.caption p {
  line-height: 0;
}
.caption {
  background: #f2f2f2;
}
.promise-text {
  text-align: left;
  line-height: 2;
}
.promise-text p {
  margin-top: 4%;
}
.thumbnail {
  text-align: left;
  line-height: 6;
}
.narrative {
  text-align: right;
}
.narrative p {
  line-height: 2;
  text-align: justify;
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0.4px;
}
.aboutus-overtext{
  
  background-color: white;
  position: absolute;
  color: black;
  left: -2%;
  top: 20%;
  padding-left: 15%;
  padding-top: 2%;
  padding-right: 7%;
  padding-bottom: 2%;
  border-radius: 8px;
  line-height: 1.5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.img-container{
left: 35%;
position: relative;
}
.aboutus-overtext h3 {
font-size: 300%;
text-align: left;

}
.aboutus-overtext p {
font-size: 20px;
text-align: left;
font-weight: 300;
}
.img-display{
position: sticky;
left: 2%;
}
.award{
display: flex;
flex-direction: row;

}

.director .architect{
margin-bottom: 20px !important;
}

.awardcard{
background-color: #CED7F0;
}

@media screen and (max-width: 280px){

}

@media (min-width: 700px) and (max-width: 720px){
  .narrative {
    font-size: 100%;
    padding: 5%;
    line-height: 0%;
  }
  .promise-icon{
    padding: 8%;
    margin-left: -5%;
  }
  .promise-text{
    margin-top: -8%;
  }
}

@media (min-width: 768px) and (max-width: 950px){
  .aboutus-overtext{
  background-color: white;
  position: absolute;
  color: black;
  top: 0%;
  padding-left: 15%;
  padding-top: 2%;
  padding-right: 7%;
  padding-bottom: 2%;
  border-radius: 8px;
  line-height: 1.5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.img-container{
left: 35%;
position: relative;
}
.aboutus-overtext h3 {
font-size: 200%;
text-align: left;

}
.aboutus-overtext p {
font-size: 60%;
text-align: left;

}
.img-display{
  position: sticky;
  left: 22%;
}

}
@media (min-width: 481px) and (max-width: 767px) {
.aboutus-overtext{
  background-color: white;
  position: relative;
  color: black;
  left: -2%;
  padding-left: 15%;
  padding-top: 2%;
  padding-right: 7%;
  padding-bottom: 2%;
  border-radius: 8px;
  line-height: 1.5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.img-container{
left: 0%;
position: relative;
}
.aboutus-overtext h3 {
font-size: 300%;
text-align: left;

}
.aboutus-overtext p {
font-size: 100%;
text-align: left;

}
.img-display{
  position: sticky;
  left: 2%;
}
.directors{
  margin-top: 2%;
}
.narrative{
  font-size: 100%;
  line-height: 0%;
}
.col-md-3{
  width: 50%;
}
.displayaward{
display: flex;
flex-direction: column;

}
.displayaward.col-md-3 h1{
text-align: center;
}
}
@media (min-width: 281px) and (max-width: 480px) {
.aboutus-overtext{
  background-color: white;
  position: relative;
  color: black;
  padding-left: 10%;
  padding-top: 2%;
  padding-right: 8%;
  padding-bottom: 2%;
  border-radius: 8px;
  line-height: 1.5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.img-container{
left: -0%;
position: relative;
}
.aboutus-overtext h3 {
font-size: 200%;
text-align: left;

}
.aboutus-overtext p {
font-size: 18px;
text-align: justify;
}
.img-display{
  position: sticky;
  left: 22%;
}
.narrative{
  font-size: 100%;
  line-height: 0%;
}
.col-md-3{
  width: 50%;
}
.displayaward h1{
font-size: 24px !important;
}
.awardcard h4{
font-size: 18px !important;
}
.awardcard p{
font-size: 16px !important;
}
.awardcard1 h4{
font-size: 18px !important;
}
.awardcard1 p{
font-size: 15px !important;
}
.displayaward br{
display: none;
}


}

@media screen and ( max-width : 480px ) {
  .director .architect {
    margin-bottom: 0px !important;
  }
  .mobSpec {
    margin-bottom: 39px !important;
  }
}

@media screen and ( min-width : 320px ) and ( max-width : 400px ) {
  .mobSpec {
    margin-bottom: 1.2rem !important;
  }
}
@media screen and (max-width: 280px){
  .aboutus-overtext{
    background-color: white;
    position: relative;
    color: black;
    padding-left: 15%;
    padding-top: 2%;
    padding-right: 7%;
    padding-bottom: 2%;
    border-radius: 8px;
    line-height: 1.5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  .img-container{
  left: -0%;
  position: relative;
  }
  .aboutus-overtext h3 {
  font-size: 200%;
  text-align: left;
  
  }
  .aboutus-overtext p {
  font-size: 60%;
  text-align: left;
  
  }
  .img-display{
    position: sticky;
    left: 22%;
  }
  
  .award{
    display: flex;
    flex-direction: column;
    
  }
.aboutus-overtext p {
  font-size: 16px;
  text-align: left;
}
.about_main .abtFold{
  padding-right: 0 !important;
}
.mobSpec{
  margin-bottom: auto !important;
}
.thumbnail img{
  margin-left: 6%;
}
.promise-text{
  margin-top: -10%;
}
.turnk-head{
  margin-top: 5%;
}
}


