

.navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
 .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #011E6C !important;
}

.nav-link {
    padding: 25px 5px;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    color: #18181B;
}
.nav-link button{
    border-radius: 8px;
    border: 2px solid #011E6C;
    color: #011E6C;
}
.nav-link.special-btn:hover{
    border-bottom: none !important;
}
.nav-link button:hover{
    border-radius: 8px;
    background-color:  #011E6C;
    color: white;
}

.header .dropdown-item:focus, .header .dropdown-item:hover {
    color: #011E6C;
    background-color: #FFFBF8;
}
.header .dropdown-divider {
    margin: 0.5rem 0;
    border-top: 1px solid #E3E5EF;
    color: #E3E5EF;
}
.header .dropdown-item{
    padding: .25rem 1rem !important;
}
.header .navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: #FFFBF8;
    backdrop-filter: blur(5px);
}
.container.logo{
    display: flex;
    flex-direction: row;
}
.footer{
    display: flex;
    flex-direction: row;
}
.nav_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.navbar-light .navbar-nav .nav-link {
    color: #18181B;
}
.brand-logo.navbar-brand{
    width: 13%;
}
.non-active{
    color: var(--secondary-color) !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
    color: var(--primary-color) !important;
}
.header{
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}

.header button.navbar-toggler.collapsed {
    border: none;
}
.header .navbar-toggler-icon{
    background-image: url('../Images/hamburger-icon.png');
}
.navbar{
    --bs-navbar-toggler-focus-width: 0 !important;
}
div#offcanvasNavbar-expand-lg {
    width: 60%;
}
.offcanvas.offcanvas-end.offcanvas-toggling {
    width: 60%;
}

.offcanvas-header .btn-close {
    font-size: 20px;
}

@media screen and ( max-width : 1100px ) {
    .navbar-brand {
        width: 30%;
    }
    .container.logo{
        display: flex;
        flex-direction: row;
    }
    .footer{
        display: flex;
        flex-direction: row;
    }
    .nav_footer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media screen and ( max-width : 820px ) {
    .navbar-brand {
        width: 46%;
    }
}

@media screen and (min-width : 700px) and (max-width: 720px) {
    .navbar-brand {
        width: 35%;
    }
    .container.logo{
display: flex;
flex-direction: column;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .nav_footer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .navbar-toggler-icon {
        font-size: 35px;
    }
    .navbar-brand.nav-link{
        width: 30%;;
    }
 
}


@media screen and (min-width : 480px) and (max-width: 568px) {
    .navbar-brand {
        width: 30%;
    }
    .container.logo{
display: flex;
flex-direction: column;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .nav_footer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media screen and (max-width : 480px)  {
    
    .brand-logo.navbar-brand {
        width: 35%;
    }
    .container.logo{
display: flex;
flex-direction: column;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .nav_footer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .navbar-light .navbar-brand.branded {
        width: 30% !important;
    }
    .dropdown-menu.show{
        border: none;
        position: relative;
        padding-top: 0%;

    }
    .xyz.offcanvas-body{
        margin-left: 5%;
    }
    .nav-link.secondary-txt.nav-link{
        font-size: 16px;
    }
    .nav-link.secondary-txt.nav-link button{
        font-size: 16px;
    }
    .navbar-toggler-icon {
        font-size: 30px;
    }
  
}

@media screen and (max-width : 280px)  {
    
    .nav-link {
        padding: 2px 5px;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        color: #18181B;
    }
    .nav-link button {
        border-radius: 8px;
        border: 2px solid #011E6C;
        color: #011E6C;
        font-size: 9px;
    }
    .nav-link.special-btn:hover{
        border-bottom: none !important;
    }
    .nav-link button:hover{
        border-radius: 8px;
        background-color:  #011E6C;
        color: white;
    }
  
}










