.footer-bg{
    background-image: url('../Images/footerbg.png');
    background-repeat: no-repeat;
    background-size:cover;
}
.footer_form{
    background-image: url('../Images/contactform.png');
    background-repeat: no-repeat;
    background-size:cover;
    padding: 2%;
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
}
.footer_form input {
    width: 100%;
    margin: 1px 0;
    background-color: transparent;
    box-sizing: border-box;
    padding-bottom: 3%;
    border: solid grey;
    border-width:0px 0px 0.5px 0px;
 }
 .footer_form input:focus {
    outline:none;
    border-width:0px 0px 1px 0px;
 }
 .footer_form select {
    width: 100%;
    margin: 1px 0;
    background-color: transparent;
    box-sizing: border-box;
    border: solid grey;
    /* color: rgba(55, 65, 79, 0.8); */
    font-size: 16px;
    border-width:0px 0px 0.5px 0px;
 }
 .footer_form select:focus {
    outline:none;
    border-width:0px 0px 1px 0px;
 }

 
 .footer_form button{
   border-radius: 50px;
   background: #011E6C;
   color: white;
   border: none;
   width: 100%;
   padding: 3%;
   -webkit-border-radius: 50px;
   -moz-border-radius: 50px;
   -ms-border-radius: 50px;
   -o-border-radius: 50px;
}
.footer-design p{
   font-size: 18px !important;
 }
@media screen and (max-width: 767px) {

   .footer_form.col-md-4{
     width: 100%;
   }
   .footer-design h1{
   font-size: 24px !important;
   }
   .footer-design p{
      font-size: 16px !important;
   }
   
 }

