.homefooter-bg{
    background-image: url('../Images/homefooter.png');
    background-repeat: no-repeat;
    background-size:cover;
}
.homefooter{
  background-color: white;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.homefooter input {
    width: 100%;
    margin: 1px 0;
    background-color: transparent;
    box-sizing: border-box;
    border: solid grey;
    padding-bottom: 3%;
    border-width:0px 0px 0.5px 0px;
 }
 .homefooter input:focus {
    outline:none;
    border-width:0px 0px 1px 0px;
 }
 .homefooter select {
  width: 100%;
  margin: 1px 0;
  background-color: transparent;
  box-sizing: border-box;
  border: solid grey;
  border-width:0px 0px 0.5px 0px;
  padding-bottom: 10px !important;
}
.homefooter option.droplabel{
  color: red;
  
}
.homefooter select:focus {
  outline:none;
  border-width:0px 0px 1px 0px;
}
 

 
 .homefooter button{
   border-radius: 50px;
   background: #011E6C;
   color: white;
   border: none;
   width: 100%;
   padding: 3%;
   -webkit-border-radius: 50px;
   -moz-border-radius: 50px;
   -ms-border-radius: 50px;
   -o-border-radius: 50px;
}
.home-footer-text p{
  font-size: 18px;
}

@media screen and (max-width: 767px) {

  .homefooter.col-md-4{
    width: 100%;
  }
}

@media screen and ( max-width : 280px ) {
  #footer .footer-bg{
    padding: 0 !important;
    margin-bottom: 30px !important;
  }
  #Footer-map .homefooter-bg{
    padding: 0 !important;
  }
}

