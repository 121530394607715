#archicomhome {
  display: table;
  height: 400px;
  position: relative;
  width: 100%;
  background-size: cover;
}
.commercial_imgtext {
  background: url('../Images/residential.png') no-repeat center center;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background-size: cover;
}
.commercial_imgtext p {
  font-size: 150%;
  color: #4b5563;
}
.headings{
  border-radius: 8px;
background: rgba(255, 255, 255, 0.12);
box-shadow: -2px -4px 12px 0px rgba(0, 0, 0, 0.10) inset;
backdrop-filter: blur(16px);
padding: 6px 0px;
}
.commercial_imgtext h3 {
  font-size: 300%;
  font-weight: 400;
  
}
.commercial_para {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: Sofia Sans;
  font-size: 113%;
  padding: 3% 10% 3% 10%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.commercial_offer {
  background-color: #f5f7ff;
}
.commercial_offer img {
  width: 100%;
}
.commercial_offer p {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: Sofia Sans;
  font-size: 300%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 1%;
}
.commercial_offerimg {
  background-color: white;
  border-radius: 12px;
}
.commercial_offerimg img {
  padding: 1%;
  width: auto;
}
.commerial_design {
  display: flex;
  text-align: left;
  justify-content: space-around;
  padding: 2% 20% 2% 20%;
  gap: 10%;
}
.design_process img {
  width: auto;
  height: auto;
}
.design_process p {
  color: #5e6a7b;
  font-family: Sofia Sans;
  font-size: 88%;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.design_process h4 {
  color: var(--gray-900, #18181b);
  font-family: Sofia Sans;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  margin-left: 4%;

  letter-spacing: 0.2px;
}

.thumbnail {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.caption h4 {
  color: white;
  margin: 0;
  font-size: 250%;
  height: 10rem;
  margin-top: -30%;
}

.caption .special-h4 {
  font-size: 250%;
  margin-top: -35%;
}

@media (max-width: 720px) and (min-width: 700px) {
  #archicomhome {
    display: table;
    height: 300px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .commercial_imgtext {
    background: url('../Images/residential.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .commercial_imgtext p {
 line-height: 0;
  }
  .commercial_imgtext h3 {
    font-size: 33px
  }
  .commerial_design {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  .design_process img {
    width: auto;
    height: auto;
  }
  .design_process p {
    color: #5e6a7b;
    font-family: Sofia Sans;
    font-size: 88%;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .design_process h4 {
    color: var(--gray-900, #18181b);
    font-family: Sofia Sans;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: normal;
    margin-left: 4%;

    letter-spacing: 0.2px;
  }
  .design_process{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .design_display{
    display: flex;
    flex-direction: column;
  }
 
  .gola.d-block {
    display: none;
  }
  .arch-des-proc{
    display: none;

  }

  .commercial_para {
    color: var(--gray-900, #18181b);
    text-align: left;
    font-family: Sofia Sans;
    font-size: 113%;
    margin-top: 4%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: justify;
  }
  .commercial_offer p{
    font-size: 28px;
padding-left: 17%;
padding-right: 17%;
padding-top: 4%;
  }
  .commercial_offer img{
    height: 190px;
  }
}

@media screen and (max-width: 480px) {
  #archicomhome {
    display: table;
    height: 220px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .commercial_imgtext {
    background: url('../Images/residential.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .commercial_imgtext p {
 line-height: 0;
  }
  .commercial_imgtext h3 {
    font-size: 33px
  }
  .commerial_design {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  .design_process img {
    width: auto;
    height: auto;
  }
  .design_process p {
    color: #5e6a7b;
    font-family: Sofia Sans;
    font-size: 88%;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .design_process h4 {
    color: var(--gray-900, #18181b);
    font-family: Sofia Sans;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: normal;
    margin-left: 4%;

    letter-spacing: 0.2px;
  }
  .design_process{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .design_display{
    display: flex;
    flex-direction: column;
  }
 
  .gola.d-block {
    display: none;
  }
  .arch-des-proc{
    display: none;

  }

  .commercial_para {
    color: var(--gray-900, #18181b);
    text-align: left;
    font-family: Sofia Sans;
    font-size: 113%;
    margin-top: 4%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: justify;
  }
  .commercial_offer p{
    font-size: 24px;
padding-left: 17%;
padding-right: 17%;
padding-top: 4%;
  }
  .commercial_offer img{
    height: 140px;
  }
}
@media screen and (max-width: 280px) {
  .commercial_imgtext h3 {
    font-size: 28px !important;
  }
  .commercial_offer p {
    font-size: 24px;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
}
.design_display p{
  margin-top: -5%;
}
.design_process img{
  margin-bottom: 5% !important;
}
.residential_design{
  padding: 0% 5% !important;
}
  
}