.nav-foot-color{
    color: #18181B;
}
.nav-foot-color:hover{
    color: #18181B;
}
.footCustColor{
    background-color: white;
}
.foot-color-primary{
    background-color: #F5F7FF;
}

.navbar-brand.logo {
    width: 13%;
}

@media screen and (min-width : 700px) and (max-width: 720px) {
    .navbar-brand.logo{
        width: 35% !important;
        align-self: flex-start;
        margin-left: 30px;
    }
 
}


@media screen and ( max-width : 480px )  {
    .navbar-brand.logo{
        width: 45% !important;
        align-self: flex-start;
        margin-left: 30px;
    }
}

@media screen and ( max-width : 280px ) {
    .nav_footer .foldNavStyl, .container.footer{
        padding: 0 10px !important;
    }
    .navbar-brand.logo{
        margin-left: 0;
    }
    
}

