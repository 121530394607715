#archihome {
  display: table;
  height: 400px;
  position: relative;
  width: 100%;
  background-size: cover;
}
.residential_imgtext {
  background: url('../Images/archCommercial.png') no-repeat center center;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background-size: cover;
}
.residential_imgtext p {
  font-size: 150%;
  color: #4b5563;
}
.residential_imgtext h3 {
  font-size: 300%;
  font-weight: 400;
}
.headings{
  border-radius: 8px;
background: rgba(255, 255, 255, 0.12);
box-shadow: -2px -4px 12px 0px rgba(0, 0, 0, 0.10) inset;
backdrop-filter: blur(16px);
padding: 6px 0px;
}
.residential_para {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: Sofia Sans;
  font-size: 113%;
  padding: 3% 10% 3% 10%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.residential_offer {
  background-color: #f5f7ff;
  
}
.residential_offer img {
  width: 100%;
}
.residential_offer p {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: Sofia Sans;
  font-size: 300%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 1%;
}

.residential_design {
  display: flex;
  text-align: left;
  justify-content: space-between;
  padding: 4% 10%;
  gap: 10%;
}

.design_process img {
  width: auto;
  height: auto;
}
.design_process p {
  color: #5e6a7b;
  font-family: Sofia Sans;
  font-size: 88%;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.design_process h4 {
  color: var(--gray-900, #18181b);
  font-family: Sofia Sans;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  margin-left: 4%;

  letter-spacing: 0.2px;
}
.thumbnail {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.caption h4 {
  color: white;
  margin: 0;
  font-size: 150%;
  height: 10rem;
  margin-top: -30%;
}

.archResHead{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.arch-des-proc{
  top: 40%;
  left: 11%;
  width: 70% !important;
}

.arch-des-proc hr{
  height: 0;
  margin: 0;
  border-top: 4px dashed #ff8200;
  background-color: transparent;
}

.gola{
  position: absolute;
  width: 2.5% !important;
  top: 34.5%;
  z-index: 10;
}

.gola.gola1, .gola.gola2, .gola.gola3, .gola.gola4 {
  margin-left: 20px !important;
}

@media (max-width: 720px)  and (min-width: 700px) {
  #archihome {
    display: table;
    height: 300px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .residential_imgtext {
    background: url('../Images/archCommercial.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .residential_imgtext p {
  line-height: 0;
  }
  .residential_imgtext h3 {
    font-size: 33px !important
  }
 
  .commerial_design {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  .residential_design{
    display: flex;
    flex-direction: column;
  }
  .design_process{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .design_display{
    display: flex;
    flex-direction: column;
  }
  .design_display p {
    margin-top: -5% !important;
}

  .gola.d-block {
    display: none;
  }
  .arch-des-proc{
    display: none;

  }
  .residential_para {
    color: var(--gray-900, #18181b);
    text-align: left;
    font-family: Sofia Sans;
    font-size: 113%;
    padding: 3% 10% 3% 10%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top: 5%;
    text-align: justify;
  }
  .residential_offer p{
font-size: 24px;
padding-left: 17%;
padding-right: 17%;
padding-top: 4%;

  }
  .residential_offer img{
    height: 190px;
  }
  .residential_imgtext h1{
    font-size: 32px !important;
  }
  .gola, .gola.gola4, .gola.gola1, .gola.gola2, .gola.gola3 {
    display: none !important;
  }
  .residential_offer p {
    font-size: 28px;
    padding-left: 17%;
    padding-right: 17%;
    padding-top: 4%;
}
.residential_design{
  display: flex;
  flex-direction: column;

}
.design_process{
  display: flex;
  flex-direction: row;
  align-items: center;

}
.design_process img{
  margin-bottom: 1% !important;
}

.design_display{
  display: flex;
  flex-direction: column;
}
.desProcess{
  margin-top: -10px !important;
}
.arch-spl{
  /* padding-right: 10%; */
}

  
}

/* @media screen and (max-width: 1024px) {
  #archihome {
    display: table;
    height: 400px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .residential_imgtext {
    background: url('../Images/archCommercial.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .residential_imgtext p {
    font-size: 150%;
    color: #4b5563;
  }
  .residential_imgtext h3 {
    font-size: 300%;
    font-weight: 400;
  }
  .residential_para {
    color: var(--gray-900, #18181b);
    text-align: center;
    font-family: Sofia Sans;
    font-size: 113%;
    padding: 3% 10% 3% 10%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .residential_offer {
    background-color: #f5f7ff;
    
  }
  .residential_offer img {
    width: 100%;
  }
  .residential_offer p {
    color: var(--gray-900, #18181b);
    text-align: center;
    font-family: Sofia Sans;
    font-size: 300%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    padding: 1%;
  }
  
  .residential_design {
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding: 4% 10%;
    gap: 10%;
  }
  .design_process img {
    width: auto;
    height: auto;
  }
  .design_process p {
    color: #5e6a7b;
    font-family: Sofia Sans;
    font-size: 88%;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .design_process h4 {
    color: var(--gray-900, #18181b);
    font-family: Sofia Sans;
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    margin-left: 4%;
  
    letter-spacing: 0.2px;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    height: 10rem;
    margin-top: -30%;
  }
  
  .archResHead{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  
  .arch-des-proc{
    top: 40%;
    left: 15%;
    width: 71% !important;
  }
  
  .arch-des-proc hr{
    height: 0;
    margin: 0;
    border-top: 4px dashed #ff8200;
    background-color: transparent;
  }
  
  .gola{
    position: absolute;
    width: 2.5% !important;
    top: 34.5%;
    z-index: 10;
  }
  
  .gola.gola1, .gola.gola2, .gola.gola3, .gola.gola4 {
    margin-left: 20px !important;
  }
} */

@media screen and (max-width: 568px) {
  .commerial_design {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  .residential_design{
    display: flex;
    flex-direction: column;
   

  }
  .design_process{
    display: flex;
    flex-direction: row;
    align-items: start;
  }
  
  .design_display{
    display: flex;
    flex-direction: column;
    
  }
 
  .gola.d-block {
    display: none;
  }
  .arch-des-proc{
    display: none;

  }
 
}
/* Mediaquery */
@media screen and (max-width: 480px) {
  #archihome {
    display: table;
    height: 220px;
    position: relative;
    width: 100%;
    background-size: cover;
  }
  .residential_imgtext {
    background: url('../Images/archCommercial.png') no-repeat center center;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
  }
  .residential_imgtext p {
  line-height: 0;
  }
  .residential_imgtext h3 {
    font-size: 33px !important
  }
 
  .commerial_design {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
    padding: 2% 20% 2% 20%;
    gap: 10%;
  }
  .residential_design{
    display: flex;
    flex-direction: column;
   

  }
  .design_process{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .design_display{
    display: flex;
    flex-direction: column;
  }
 
  .gola.d-block {
    display: none;
  }
  .arch-des-proc{
    display: none;

  }
  .residential_para {
    color: var(--gray-900, #18181b);
    text-align: left;
    font-family: Sofia Sans;
    font-size: 113%;
    padding: 3% 10% 3% 10%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top: 5%;
    text-align: justify;
  }
  .residential_offer p{
font-size: 24px;
padding-left: 17%;
padding-right: 17%;
padding-top: 4%;

  }
  .residential_offer img{
    height: 140px;
  }
  .residential_imgtext h1{
    font-size: 32px !important;
  }

  
}
@media screen and (max-width: 280px) {
  .residential_imgtext h3 {
    font-size: 28px !important;
  }
  .residential_offer p {
    font-size: 24px;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
}


  
}
