/* Desktop Styles */
.special-hr{
  opacity: 100;
  width: 3%;
  display: inline-block;
  margin-bottom: 9px;
  margin-right: 5px;
}


@media (min-width: 1024px) {
  .homepage_main{
    background-color: #F5F7FF;
  }
  .homepage-overtext{
    background-color: #011E6C;
    position: absolute;
    color: white;
    left: -2%;
    top: 11%;
    padding-left: 15%;
    padding-top: 5%;
    padding-right: 7%;
    padding-bottom: 5%;
    border-radius: 8px;
    line-height: 1.5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.img-container{
  left: 35%;
  position: relative;
}
.homepage-overtext h3 {
  font-size: 48px;
  text-align: left;
  font-weight: 400;

}
.text-para p{
font-size: 20px;
}
.homepage-overtext p {
  font-size: 140%;
  text-align: left;

}
.home-text{
  top: 42%;
  left: -0%;
  color: white;

}
  .box-border {
    display: flex;
    width: 100%;
  }
  .homeimg-display{
    position: relative;
    left: 2%;
  }
  .homepage_head{
    position: relative;
    top: 34px;
    padding: 3%;
    left: 34px;
    border: #011E6C 1px solid;
    border-radius: 8px;
  }
  .maintxt{
    background-image: url('../Images/homeimg2.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .maintxt h3{
font-size: 300%;
  }
  .maintxt p{
    font-size: 150%;
    margin-top: 6%;
  }
  .homepage_icontxt img{
font-size: 200%;
height: 10vh;
  }
  
  .homepage_content{
    display: flex;
    flex-direction: row;
  }
  .homepage_materialbg{
    background: url('../Images/materialbg.png') no-repeat ;
    
  }
  .homepage_studio{
  margin-left: 5%;

  }
  .homepage_icontxt{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 5%;
  }
  .homepage_icontxt img{
    height: 8vh;
  }
  .homepage_icontxt p{
    font-size: 140%;
  }
  .homepage_iconshow{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .materialbox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
  }
  .materialboxbtn{
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
background: none;
padding: 0.5%;
text-align: center;
  }
  .material-info{
    background-image: url('../Images/materialbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;

  }
  .materialboxbtn {
    color: #011E6C;
    padding-left: 3%;
    padding-right: 3%;
padding-top: 1%;
padding-bottom: 1%;
font-size: 112%;
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.materialboxbtn:hover{
    border-radius: 50px;
    background-color:  #011E6C;
    color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

  .material-info h3{
text-align: left;
font-size: 300%;

  }
  .material{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: #EE8D1F 2px solid;
    border-bottom: #EE8D1F 2px solid;

  }
  .material img{
    height: 10vh;
    margin-top: 10px;
  }
  .material p{
    line-height: 3;
    font-size: 20px;
  }
  .service{
    display: flex;
    flex-direction: row;
    gap: 4%;
    align-items: center;
    justify-content: center;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .service img{
    height: 35vh;
  }
  .service-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 11%;
    padding-right: 11%;
    padding-top: 3%;

  }
  .service-head1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
  }
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    background-color: transparent;
  }
  .homepage_shows img{
    height: 8vh;
  }
}

/* Tablet Styles */
@media (max-width: 1023px) and (min-width: 768px) {

  .homepage_main{
    background-color: #F5F7FF;
  
  }
  .homepage-overtext{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #011E6C;
    position: absolute;
    color: white;
    left: -2%;
    padding-left: 13%;
    padding-top: 5%;
    padding-right: 1%;
    padding-bottom: 5%;
    border-radius: 8px;
    top:7%;
    line-height: 1.5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    

}

.homepage-overtext h3 {
  font-size: 230%;
  text-align: left;

}
.homepage-overtext p {
  font-size: 100%;
  text-align: left;

}
  .box-border {
    display: flex;
    width: 100%;
  }
  .homeimg-display{
    position: relative;
    left: 25%;
  }
  .homepage_head{
    position: relative;
    top: 34px;
    padding: 3%;
    left: 34px;
    border: #011E6C 1px solid;
    border-radius: 8px;
  }
  
  .maintxt.col-md-12{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .maintxt h3{
font-size: 300%;
text-align: left;
  }
  .maintxt p{
    font-size: 120%;
    margin-top: 2%;
  }
  

  .homepage_content{
    display: flex;
    flex-direction: row;
  }
  .homepage_materialbg{
    background: url('../Images/materialbg.png') no-repeat ;
  }
  .homepage_studio{
  margin-left: 5%;

  }
  .homepage_icontxt{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  }
  .homepage_icontxt img{
    height: 3vh;
  }
  .homepage_iconshow{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .homepage_shows.col-sm-12{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5%;
    padding: 0;
  }
  .homepage_shows.col-sm-12 h3{
    font-size: large;

  }
  .homepage_shows.col-sm-12 img{
    height: 5vh;
  }
  .materialbox{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
  }
  .material.col-md-2 {
    width: 20%;
  }
  .materialboxbtn{
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
background: none;
padding: 0.5%;
text-align: center;
  }
  .material-info{
    background-image: url('../Images/materialbg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;

  }
  .materialboxbtn {
    color: #011E6C;
    padding-left: 3%;
    padding-right: 3%;
padding-top: 1%;
padding-bottom: 1%;
font-size: 112%;
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.materialboxbtn:hover{
    border-radius: 50px;
    background-color:  #011E6C;
    color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

  .material-info h3{
text-align: left;
font-size: 300%;

  }
  .material{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: #EE8D1F 2px solid;
    border-bottom: #EE8D1F 2px solid;

  }
  .material img{
    height: 7vh;
  }
  .material p{
    line-height: 3;
  }
  .service{
    display: flex;
    flex-direction: row;
    gap: 4%;
    align-items: center;
    justify-content: center;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .service img{
    height: 35vh;
  }
  .service-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 17%;
    padding-right: 17%;
    padding-top: 3%;

  }
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    background-color: transparent;
  }
  .homepage_shows img{
    height: 7vh;
  }
}

@media (max-width: 728px) and (min-width: 700px) {
  .homepage_main{
    background-color: #F5F7FF;
  
  }
  .homepage-overtext{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #011E6C;
    position: relative;
    color: white;
    right: 10%;
    top: 80%;
    margin-top: -45px;
    padding-left: 0%;
    padding-top: 5%;
    padding-right: 0%;
    padding-bottom: 5%;
    border-radius: 8px;
    line-height: 1.5;
    margin-right: 35px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    

}

.homepage-overtext h3 {
  font-size: 28px;
  text-align: left;
  margin-left: -16%;

}
.homepage-overtext p {
  font-size: 18px;
  text-align: left;

}
  .box-border {
    display: flex;
    width: 100%;
  }
  .homeimg-display{
    position: relative;
    left: 25%;
  }
  .homepage_head{
    position: relative;
    top: 50%;
    padding: 3%;
    left: 34px;
    border: #011E6C 1px solid;
    border-radius: 8px;
  }
  .maintxt h3{
font-size: 300%;
  }
  .maintxt p{
    font-size: 16px;
    margin-top: 6%;
  }
  .maintxt img{
  height: 80vh;
  }
  /* .homehead h1{
    font-size: 24px;
  } */
  .text-para h1{
    font-size: 24px;
  }
  .text-para p{
    font-size: 16px;
  }
  .material-info h3 {
    text-align: left;
    font-size: 28px;
}
  .homepage_content{
    display: flex;
    flex-direction: row;
  }
.homepage_shows.col-sm-12{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8%;
}
.homepage_shows.col-sm-12 h3{
  font-size: 19px;
  font-weight: 400;

}
.text-head h2{
  font-size: 24px;
}

.homepage_shows.col-sm-12 img{
  height: 8vh;

}
  .homepage_materialbg{
    background: url('../Images/materialbg.png') no-repeat ;
  }
  .homepage_studio{
  margin-left: 5%;

  }
  .homepage_icontxt img{
height: 8vh;
  }

  .homepage_icontxt{
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 5%;
    }

  .homepage_icontxt p{
   font-size: 19px;
    }
  .materialbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2%;
  }
  .material.col-md-2 {
    width: 47%;
  }
  .materialboxbtn{
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
background: none;
padding: 0.5%;
text-align: center;
  }
  .material-info{
    background-image: url('../Images/materialbg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;

  }
  .materialboxbtn {
    color: #011E6C;
    padding-left: 3%;
    padding-right: 3%;
padding-top: 1%;
padding-bottom: 1%;
font-size: 112%;
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.materialboxbtn:hover{
    border-radius: 50px;
    background-color:  #011E6C;
    color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}


  .material{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: #EE8D1F 2px solid;
    border-bottom: #EE8D1F 2px solid;

  }
  .material img{
    height: 7vh;
  }
  .material p{
    line-height: 2;
  }
  .service{
    display: flex;
    flex-direction: row;
    gap: 4%;
    align-items: center;
    justify-content: center;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .service img{
    height: 35vh;
  }
  .service-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 17%;
    padding-right: 17%;
    padding-top: 3%;

  }
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    background-color: transparent;
  }
  .homepage_shows img{
    height: 7vh;
  }
  .view-text h1{
    font-size: 28px;
  }
  .material-spcl{
    margin-right: 10px !important;
    margin-top: -1px !important;
  }

  .material-spcl p{
    margin-right: 10px !important;
    margin-top: -1px !important;
  }

}
/* Mobile Styles */
@media (max-width: 568px) {
  .homepage_main{
    background-color: #F5F7FF;
  
  }
  .homepage-overtext{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #011E6C;
    position: relative;
    color: white;
    left: -4%;
    padding-left: 13%;
    padding-top: 5%;
    padding-right: 1%;
    padding-bottom: 5%;
    border-radius: 8px;
    top:80%;
    line-height: 1.5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    

}

.homepage-overtext h3 {
  font-size: 28px;
  text-align: left;

}
.homepage-overtext p {
  font-size: 150%;
  text-align: left;

}
  .box-border {
    display: flex;
    width: 100%;
  }
  .homeimg-display{
    position: relative;
    left: 25%;
  }
  .homepage_head{
    position: relative;
    top: 34px;
    padding: 3%;
    left: 34px;
    border: #011E6C 1px solid;
    border-radius: 8px;
  }
  .maintxt h3{
font-size: 300%;
  }
  .maintxt p{
    font-size: 150%;
    margin-top: 6%;
  }
  .homepage_content{
    display: flex;
    flex-direction: row;
  }
  .homepage_materialbg{
    background: url('../Images/materialbg.png') no-repeat ;
  }
  .homepage_studio{
  margin-left: 5%;

  }
  .homepage_icontxt{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  }
  .homepage_icontxt img{
    height: 7vh;
  }
  .homepage_iconshow{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .homepage_shows.col-sm-12{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5%;
    padding: 0;
  }
  .homepage_shows.col-sm-12 h3{
    font-size: large;

  }
  .homepage_shows.col-sm-12 img{
    height: 7vh;
  }
  .materialbox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
  }
  .material.col-md-2 {
    width: 40%;
  }
  .materialboxbtn{
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
background: none;
padding: 0.5%;
text-align: center;
  }
  .material-info{
    background-image: url('../Images/materialbg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;

  }
  .materialboxbtn {
    color: #011E6C;
    padding-left: 3%;
    padding-right: 3%;
padding-top: 1%;
padding-bottom: 1%;
font-size: 112%;
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.materialboxbtn:hover{
    border-radius: 50px;
    background-color:  #011E6C;
    color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

  .material-info h3{
text-align: left;
font-size: 300%;

  }
  .material{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: #EE8D1F 2px solid;
    border-bottom: #EE8D1F 2px solid;

  }
  .material img{
    height: 7vh;
  }
  .material p{
    line-height: 3;
  }
  .service{
    display: flex;
    flex-direction: row;
    gap: 4%;
    align-items: center;
    justify-content: center;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .service img{
    height: 35vh;
  }
  .service-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 17%;
    padding-right: 17%;
    padding-top: 3%;

  }
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    background-color: transparent;
  }
  .homepage_shows img{
    height: 7vh;
  }
}

@media screen and (max-width: 480px) {

  .homepage_main{
    background-color: #F5F7FF;
  
  }
  .homepage-overtext{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #011E6C;
    position: relative;
    color: white;
    right: 10%;
    top: 80%;
    margin-top: -45px;
    padding-left: 9%;
    padding-top: 5%;
    padding-right: 2%;
    padding-bottom: 5%;
    border-radius: 8px;
    line-height: 1.5;
    margin-right: 35px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    

}

.homepage-overtext h3 {
  font-size: 28px;
  text-align: left;

}
.homepage-overtext p {
  font-size: 16px;
  text-align: left;

}
  .box-border {
    display: flex;
    width: 100%;
  }
  .homeimg-display{
    position: relative;
    left: 25%;
  }
  .homepage_head{
    position: relative;
    top: 50%;
    padding: 3%;
    left: 34px;
    border: #011E6C 1px solid;
    border-radius: 8px;
  }
  .maintxt h3{
font-size: 300%;
  }
  .maintxt p{
    font-size: 150%;
    margin-top: 6%;
  }
  /* .homehead h1{
    font-size: 24px;
  } */
  .text-para h1{
    font-size: 24px;
  }
  .text-para p{
    font-size: 16px;
  }
  .homepage_content{
    display: flex;
    flex-direction: row;
  }
.homepage_shows.col-sm-12{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8%;
}
.homepage_shows.col-sm-12 h3{
  font-size: 19px;

}
.text-head h2{
  font-size: 24px;
}

.homepage_shows.col-sm-12 img{
  height: 6vh;
}
  .homepage_materialbg{
    background: url('../Images/materialbg.png') no-repeat ;
  }
  .homepage_studio{
  margin-left: 5%;

  }

  .homepage_icontxt{
  display: flex;
  align-items: center;
  justify-content: initial;
  gap: 10%;
  }
  .homepage_icontxt img{
    height: 6vh;
  }
  .homepage_icontxt p{
    font-size: 19px !important;
  }
  .homepage_iconshow{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .materialbox{
    display: flex;
    align-items: center;
  }
  .materialboxbtn{
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
background: none;
padding: 0.5%;
text-align: center;
  }
  .container.col-md-12 h1{
    font-size: large;
  }
  
  .material-info{
    background-image: url('../Images/materialbg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 0%;
  }
  .materialboxbtn {
    color: #011E6C;
    padding-left: 4%;
    padding-right: 4%;
padding-top: 2%;
padding-bottom: 2%;
font-size: 112%;
    border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.materialboxbtn:hover{
    border-radius: 50px;
    background-color:  #011E6C;
    color: white;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

  .material-info h3{
text-align: center;
font-size: 24px;

  }
  .material{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: #EE8D1F 2px solid;
    border-bottom: #EE8D1F 2px solid;

  }
  .material img{
    height: 7vh;
  }
  .material p{
    line-height: 1;
    text-align: center;
    font-size: small;
    margin-top: 25px !important;
  }
  .service{
    display: flex;
    flex-direction: row;
    gap: 4%;
    align-items: center;
    justify-content: center;
  }
  .thumbnail {
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .home-text{
    top: 10%;
    left: -0%;
    color: white;
  }
  .service img{
    height: 35vh;
  }
  .service-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 17%;
    padding-right: 17%;
    padding-top: 3%;

  }
  .caption h4 {
    color: white;
    margin: 0;
    font-size: 150%;
    background-color: transparent;
  }
  .homepage_shows img{
    height: 7vh;
  }
  .text{
    font-size: 16px !important;
    margin-left: 1%;
  }
  .material-spcl{
    margin-right: 10px !important;
  }
  .view-text a{
    font-size: 16px !important;
  }
  .view-text h1{
    font-size: 21px !important;
    margin-bottom: 0 !important;
  }
}

@media screen and ( max-width : 280px ) {
  .view-text a{
    text-align: end;
  }
  .homepage_shows.col-sm-12 h3 {
    font-size: 19px;
    font-weight: 400;
}
.material.col-md-2 {
    width: 44%;
    /* height: 8%; */
}
.materialboxbtn {
  color: #011E6C;
  /* padding-left: 3%; */
  /* padding-right: 3%; */
padding-top: 1%;
padding-bottom: 1%;
font-size: 13px;
  border-radius: 50px;
border: 2px solid #1F344B;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.materialboxbtn:hover{
  border-radius: 50px;
  background-color:  #011E6C;
  color: white;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.material .spcl-title{
  margin-top: 7px !important;
}

}
  