.mainfooter img{
    height: 35px;
}

@media screen and (max-width: 768px) {
   /* .navbar-brand {
       width: 20%;
   } */
   .container.footer{
display: flex;
flex-direction: column;
   }
   .footer{
       display: flex;
       flex-direction: row;
   }
   .footer_icon img{
     height: 15vh;
   }

   .nav_footer{
       display: flex;
       flex-direction: row;
       justify-content: space-around;
   }
}

