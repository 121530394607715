  
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

:root{
  --primary-color : #011E6C;
  --light-color : #5E6A7B;
}
body{
  font-family: 'Sofia Sans', sans-serif;
  background-color: #F5F7FF;
}
.primary-color, .primary-txt{
  color: var(--primary-color);
}
.homehead{
  font-size: 300%;
}
.light-color{
  color : var(--light-color)
}

body.modal-open{
  padding: 0 !important;
}
h1, h2, h3, h4, h5, h6{
  font-weight: 400 !important;
}




/* --------------- Remove arrow form number input --------------- */
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

