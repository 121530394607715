.pop-img-height{
    height: 15vh !important;
}

/* .imgSize{
    height: 420px;
    width: 580px;
} */
.swiper-button-prev:after, .swiper-button-next:after{
    color: white;
    background-color: #000000;
    opacity: 0.5;
    font-size: 30px !important;
    padding: 35% 35%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}



@media screen and ( max-width : 480px ) {
    .pop-img-height{
        height: 9vh !important;
    }
    .swiper-button-prev:after, .swiper-button-next:after{
        /* color: red; */
        font-size: 20px !important;
        margin-top: -30px !important;
    }
    /* .imgSize{
        height: 280px;
        width: 316px;
    } */
    .false-font{
        font-size: 12px !important;
    }
}
@media screen and ( max-width : 280px ) {
    .pop-img-height{
        height: 9vh !important;
    }
    .swiper-button-prev:after, .swiper-button-next:after{
        /* color: red; */
        font-size: 20px !important;
        margin-top: -30px !important;
        color: white;
        background-color: #000000;
        opacity: 0.5;
    }
    /* .imgSize{
        height: 280px;
        width: 316px;
    } */
    .pop-head p{
        font-size: 10px !important;
    }
}
